.assistant{

    display: flex;
    min-width: 100%;
    height: fit-content;
}
.body_assistant{
    min-width:  calc(100% - 82px);
    height: fit-content;
    padding-left: 82px;
    display: flex;
}