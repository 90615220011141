.assistant_page{
    background: rgba(249, 250, 251, 1);
    min-width: 100%;
    height: 100vh;
    display: flex;
    
}
.assistant_messages, .assistant_controls{
    
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}
.assistant_messages{
    align-items: flex-start;
}
.assistant_controls{
   
    background: #fff;
    height: calc(100% - 2px);
    border: 1px solid rgba(223, 224, 226, 1)
}
.assistant_logo{
    min-width: calc(100% - 40px);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    padding-bottom: 0px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 700;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}
.assistant_logo svg{
    margin-right: 8px;
}
.assistant_messages_inputs{
    min-width: calc(100% - 48px);
    height: fit-content;
    padding-left: 36px;
    padding-right: 12px;
    padding-bottom: 20px;
    /* position: absolute; */
    bottom: 0;
}
.assistant_messages_body{
    width: 100%;
    height: calc(100% - 200px);
    overflow-x: auto;
}


.assistant_messages_body::-webkit-scrollbar{
    height: 2px;
    width: 4px;
}
.assistant_messages_body::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
}
 
.assistant_messages_body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--Grays-Gray-4, rgba(209, 209, 214, 1));
}



.assistant_messages_tags{
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 20px;
}
.assistant_messages_tag{
    width: calc(33% - 40px);
    height: fit-content;
    margin-right: 20px;
    background: #fff;
    border: 1px solid rgba(225, 228, 234, 1);
    padding: 10px;
    border-radius: 8px;

    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}
.assistant_messages_tag p{
    padding: 0px;
    margin: 0px;
    padding-top: 8px;
}
.assistant_messages_input{
    min-width: calc(100% - 16px);
    height: fit-content;
    background: rgba(237, 239, 242, 1);
    border-radius:26px;
    padding: 8px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.assistant_messages_skreppole{
    min-width: calc(100% - 36px);
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;
}
.assistant_messages_skreppole svg{
    margin-left: 6px;
    margin-right: 16px;
}
.assistant_messages_skreppole textarea{
    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 14.7px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    min-width: calc(100% - 56px);
    outline: none;
    border: 0px;
    background: none;
    resize: none;
    padding-top: 5px;
    position: relative;
    top: 5px;
    height: 22px;
}
.assistant_messages_skreppole input::placeholder{
    color: rgba(2, 2, 3, 0.4);
    display: flex;
    align-items: center;
}
.assistant_messages_button{
    min-width: 36px;
    height: 36px;
    cursor: pointer;
}
.assistant_messages_button:hover svg rect{
    fill:rgba(77, 77, 77, 1)
}
.assistant_messages_button.active svg rect{
    fill:rgba(9, 9, 9, 1)
}
.assistant_messages_button.active:hover svg rect{
    fill:rgba(77, 77, 77, 1)
}
.butt_resize{
    position: absolute;
    top: calc(50% - 15px);
    left: -15px;
    cursor: e-resize;
    z-index: 9999;
}
.reza{
    min-width: 100%;
}
.head_controls{
    min-width: 100%;
    height: 60px;
    border-bottom: 1px solid rgba(223, 224, 226, 1);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.tab_controls_block{
    width: 33%;
    max-width: 33%;
    min-width: 33%;
    display: flex;
    justify-content: center;
}
.tab_controls_block_menu{
    padding-left: 26px;
    padding-right: 26px;
    height: 37px;
    display: flex;
    align-items: center;
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
    line-height: 16.8px;
    letter-spacing: 0%;
    text-align: center;
    cursor: pointer;
}
.tab_controls_block_menu.active{
    border-bottom: 2px solid rgba(245, 197, 56, 1)
}
.body_controls{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-width: calc(100% - 4px);
    height: calc(100vh - 62px);
    overflow-x: auto;
    margin-right: 4px;
}

.body_controls::-webkit-scrollbar{
    height: 2px;
    width: 4px;
}
.body_controls::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
}
 
.body_controls::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--Grays-Gray-4, rgba(209, 209, 214, 1));
}



.body_controls_block{
    padding: 24px;
    min-width: calc(100% - 48px);
} 
.body_controls_block_line{
    min-width: 100%;
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    color: rgba(2, 2, 3, 1);
}
.body_controls_block_line_title{
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
    line-height: 16.8px;
    letter-spacing: -3%;
    
}  
.body_controls_block_line label{
    font-family: Manrope;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.8px;
    letter-spacing: -1%;
    min-width: 100%;
    margin-bottom: 8px;
}
.body_controls_block_line input{
    font-family: Manrope;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.8px;
    letter-spacing: -1%;
    min-width: calc(100% - 20px);
    outline: none;
    height: 38px;
    border: 1px solid rgba(225, 229, 234, 1);
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
}
.body_controls_block_line input::placeholder{
    font-family: Manrope;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.8px;
    letter-spacing: -1%;
    min-width: 100%;
    color: rgba(2, 2, 3, 0.4);
}
.body_controls_block_line textarea{
    font-family: Manrope;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.8px;
    letter-spacing: -1%;
    min-width: calc(100% - 20px);
    outline: none;
    border: 1px solid rgba(225, 229, 234, 1);
    border-radius: 8px;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 180px;
    max-height: 180px;
    padding-top: 10px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}
.body_controls_block_line textarea::placeholder{
    font-family: Manrope;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.8px;
    letter-spacing: -1%;
    min-width: 100%;
    color: rgba(2, 2, 3, 0.4);
}
textarea {
    resize: none;
}
.body_controls_block_line_piv{
    min-width: 50%;
}
.body_controls_block_line_piv_line1{
    min-width: 100%;
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
    line-height: 16.8px;
    letter-spacing: -3%;
    margin-bottom: 30px;
}
.body_controls_block_line_piv_line2{
    display: flex;
    min-width: 100%;
    font-family: Manrope;
    font-weight: 400;
    font-size: 14px;
    line-height: 14.7px;
    letter-spacing: -3%;
    color:rgba(142, 142, 147, 1);
}
.body_controls_block_line_piv_line2 svg{
    margin-left: 8px;
    cursor: pointer;
}
.body_controls_block_line span{
    min-width: 100%;
    display: flex;
    align-items: center;
    margin-top: 8px;
    color: rgba(142, 142, 147, 1);
    font-family: Manrope;
    font-weight: 400;
    font-size: 12px;
    line-height: 13.2px;
    letter-spacing: -3%;
    
}
.body_controls_block_line span svg{
    margin-right: 8px;
}
.body_controls_block_line .bigtextarea{
    max-height: 120px;
    min-height: 120px;
}
.body_controls_block_line .mintextarea{
    max-height: 80px;
    min-height: 80px;
}
.body_controls_block_linetable_head{
    min-width: calc(100% - 16px);
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Manrope;
    font-weight: 600;
    font-size: 16px;
    line-height: 16.8px;
    letter-spacing: -3%;
    background: rgba(242, 242, 242, 1);
    border-radius: 8px;
    padding: 8px;
}
.body_controls_block_linetable_col1{
    min-width: 140px;
    max-width: 140px;
}
.body_controls_block_linetable_col2{
    min-width: calc(100% - 212px);
    max-width: calc(100% - 212px);
}
.body_controls_block_linetable_col3{
    min-width: 36px;
    max-width: 36px;
}
.body_controls_block_linetable_col4{
    min-width: 36px;
    max-width: 36px;
}
.body_controls_block_linetable_body{
    min-width: calc(100% - 16px);
   
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Manrope;
    font-weight: 400;
    font-size: 14px;
    line-height: 14.7px;
    letter-spacing: -3%;
    margin-top: 8px;
    padding: 8px;
}
.blocktextarea{
    font-family: Manrope;
    font-weight: 400;
    font-size: 14px;
    line-height: 14.7px;
    letter-spacing: -3%;
    min-width: calc(100% - 32px);
    outline: none;
    border: 1px solid rgba(225, 229, 234, 1);
    border-radius: 8px;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 8px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    min-height: 30px;
    padding-bottom: 0px;
    padding-top: 8px;
    margin-bottom: 10px;
}
.blocktextarea ul{
    padding-left: 15px;
}

.body_controls_block_linetable_body textarea{
    font-family: Manrope;
    font-weight: 400;
    font-size: 14px;
    line-height: 14.7px;
    letter-spacing: -3%;
    min-width: calc(100% - 32px);
    outline: none;
    border: 1px solid rgba(225, 229, 234, 1);
    border-radius: 8px;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 8px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    min-height: 30px;
    padding-bottom: 8px;
    padding-top: 8px;
}
.body_controls_block_linetable_body textarea::placeholder{
    font-family: Manrope;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.8px;
    letter-spacing: -1%;
    min-width: 100%;
    color: rgba(2, 2, 3, 0.4);
}
.body_controls_block_linetable_col3 .check{
    cursor: pointer;
}
.textareas{
    max-height: 100px;
    overflow-x: auto;
}

.textareas::-webkit-scrollbar{
    height: 2px;
    width: 4px;
}
.textareas::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
}
 
.textareas::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--Grays-Gray-4, rgba(209, 209, 214, 1));
}
.body_controls_block_product_line1{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.body_controls_block_product_line1_name{
    display: flex;
    align-items: center;
}
.body_controls_block_product_line1_name input{
    
    font-family: Manrope;
    font-weight: 600;
    font-size: 24px;
    line-height: 28.8px;
    letter-spacing: -1%;
    border: none;
    outline: none;
    background: none;
    width: 310px;
}
.body_controls_block_product_line1_name input::placeholder{
    color:  rgba(209, 209, 214, 1);
}
.body_controls_block_product_line1_more{
    display: flex;
    align-items: center;
}
.moreproduct{
    margin-left: 8px;
}
.body_controls_block_product_line1_name .hidepen{
    display: none;
    cursor: pointer;
}
.body_controls_block_product_line1_name:hover .hidepen{
    display: block;
}
.body_controls_block_product_line2{
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 24px;
    position: relative;
    z-index: 99;
}
.body_controls_block_product_line2_name{
    margin-left: 4px;
    margin-right: 8px;
    font-family: Manrope;
    font-weight: 500;
    font-size: 12px;
    line-height: 14.4px;
    letter-spacing: -1%;
    color: rgba(142, 142, 147, 1);
}
.body_controls_block_product_line2_tools{
    font-family: Manrope;
    font-weight: 500;
    font-size: 12px;
    line-height: 14.4px;
    letter-spacing: -1%;
    color: rgba(199, 199, 204, 1);
    position: relative;
}
.body_controls_block_product_line2_tools span{
    cursor: pointer;
}
.tooltip_tools{
    position: absolute;
    width: 262px;
    height: 142px;
    border-radius: 8px;
    padding: 10px;
    border: 1px solid rgba(231, 234, 238, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    z-index: 99;
    background: #fff;
}
.check_tooltip_tools{
    display: flex;
    align-items: center;
    font-family: Manrope;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.8px;
    letter-spacing: -1%;
    margin-bottom: 17px;
    cursor: pointer;
    
}
.check_tooltip_tools svg{
    margin-right: 8px;
}
.but_tooltip_tools{
    display: flex;
    align-items: center;
    justify-content: center;
}
.but1_tooltip_tools{
    width: 85px;
    height: 36px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 8px;
    border: 1px solid rgba(209, 209, 214, 1);
    font-family: Manrope;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.8px;
    letter-spacing: -3%;
    color: rgba(2, 2, 3, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    cursor: pointer;
}
.but1_tooltip_tools:hover{
    background: rgba(242, 242, 247, 1);
}
.but2_tooltip_tools{
    width: 85px;
    height: 36px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 8px;
    border: 1px solid rgba(255, 204, 0, 1);
    background: rgba(255, 204, 0, 1);
    font-family: Manrope;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.8px;
    letter-spacing: -3%;
    color: rgba(2, 2, 3, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    cursor: pointer;
}
.but2_tooltip_tools:hover{
    border: 1px solid rgba(99, 189, 197, 1);
    background: rgba(99, 189, 197, 1);
}
.seltools{
    cursor: pointer;
}
.body_controls_block_product_line3{
    display: flex;
    align-items: center;
    font-family: Manrope;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.8px;
    letter-spacing: -1%;
    color:  rgba(142, 142, 147, 1);
    cursor: pointer;
}
.body_controls_block_product_line3 svg{
    margin-right: 8px;
    cursor: pointer;
}
.morefunck_block{
    position: relative;
    cursor: pointer;
}
.morefunck{
    position: absolute;
    width: 142px;
    height: 48px;
    border-radius: 8px;
    padding: 12px;
    border: 1px solid rgba(231, 234, 238, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    background: #fff;
    z-index: 99;
    top: 30px;
    right: 0px;
}
.morefunck_line1{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-family: Manrope;
    font-weight: 500;
    font-size: 12px;
    line-height: 12.6px;
    letter-spacing: -3%;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
}
.morefunck_line1 svg{
    margin-right: 8px;
}
.morefunck_line2{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-family: Manrope;
    font-weight: 500;
    font-size: 12px;
    line-height: 12.6px;
    letter-spacing: -3%;
    color: rgba(255, 59, 48, 1);
    cursor: pointer;
}
.morefunck_line2 svg{
    margin-right: 8px;
}
.body_controls_block_product_texteditor{
    min-width: 100%;
    margin-bottom: 30px;
    position: relative;
}
.body_controls_block_product_texteditor_control{
    width: 227px;
    height: 41px;
    padding-right: 8px;
    padding-left: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(231, 234, 238, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    position: relative;
    top: -60px;
    z-index: 999;
}
.texteditor_control_logo{
    min-width: 59px;
    height: 100%;
    display: flex;
    align-items: center;
    font-family: Manrope;
    font-weight: 400;
    font-size: 12px;
    line-height: 12.6px;
    letter-spacing: -3%;
    color: rgba(0, 0, 0, 1);
}
.texteditor_control_font{
    min-width: 68px;
    border-left:  1px solid rgba(231, 234, 238, 1);
    border-right:  1px solid rgba(231, 234, 238, 1);
    height: 100%;
    display: flex;
    align-items: center;
    font-family: Manrope;
    font-weight: 400;
    font-size: 12px;
    line-height: 12.6px;
    letter-spacing: -3%;
    cursor: pointer;
    position: relative;
}
.texteditor_control_font svg:first-child{
    margin-right: 0px;
}
.texteditor_control_font svg:last-child{
    margin-left: 1px;
}
.texteditor_control_text{
    min-width: 74px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
}
.texteditor_control_text{
    cursor: pointer;
}
.texteditor_control_logo img{
    width: 16px;
    margin-right: 4px;
}
.texteditor_control_text button{
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}
.editortext{
    outline: none;
    padding-bottom: 70px;
}
.blockfonttool{
    min-width: 155px;
    height: 124px;
    border-radius: 8px;
    padding: 12px;
    background: #fff;
    border: 1px solid rgba(231, 234, 238, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50px;
}
.blockfonttool_line{
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: none;
    outline: none;
    border: 0px;
    padding: 0;
    margin: 0;
    margin-bottom: 8px;
    cursor: pointer;
}
.blockfonttool_line_icon{
    min-width: 24px;
    max-width: 24px;
    display: flex;
    align-items: center;
}
.blockfonttool_line_text{
    min-width: 120px;
    max-width: 120px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.blockfonttool_line_text p:first-child{
    font-family: Manrope;
    font-weight: 500;
    font-size: 12px;
    line-height: 12.6px;
    letter-spacing: -3%;
    color: rgba(0, 0, 0, 1);
    padding: 0;
    margin: 0;
    display: block;
    padding-bottom: 3px;
    min-width: 100%;
    text-align: left;
}
.blockfonttool_line_text p:last-child{
    font-family: Manrope;
    font-weight: 500;
    font-size: 8px;
    line-height: 8.4px;
    letter-spacing: -3%;
    color: rgba(174, 174, 178, 1);
    padding: 0;
    margin: 0;
}
.clientmess{
    max-width: 500px;
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.adminmess{
    max-width: 700px;
    display: flex;
    align-items: flex-start;
    margin-left: 20px;
    padding-right: 40px;
}
.clientmess_text{
    max-width: 540px;
    margin-left: 8px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 20px;
    background: rgba(237, 239, 242, 1);
}
.clientmess_text p{
    font-family: Manrope;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.8px;
    letter-spacing: -3%;
    
}
.admin_text{
    max-width: 680px;
    margin-left: 8px;
    padding-right: 20px;
    border-radius: 20px;
    font-family: Manrope;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.8px;
    letter-spacing: -3%;
}
.admin_text_h{
    font-family: Manrope;
    font-weight: 700;
    font-size: 16px;
    line-height: 16.8px;
    letter-spacing: -3%;
    margin-bottom: 10px;
}
.admin_text span{
    display: inline-block;
    min-width: 100%;
}

.clientmess_line{
    min-width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 29px;
}
.admin_line{
    min-width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 29px;
}
.clientmess_icons{
    cursor: pointer;
    opacity: 0;
}

.clientmess:hover .clientmess_icons{
    opacity: 1;
}
.clientmess_text_buts{
    min-width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
}
.clientmess_text_buts_col{
    min-width: 212px;
    max-width: 212px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.clientmess_text_but{
    width: 100px;
    height: 36px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(209, 209, 214, 1);
    font-family: Manrope;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.8px;
    letter-spacing: -3%;
    cursor: pointer;
}
.b_y{
 background: rgba(255, 204, 0, 1);   
}
.clientmess_text_but:hover{
    background: rgba(199, 199, 204, 1);
}
.b_y:hover{
    background: rgba(99, 189, 197, 1);  
}
.admin_icons img{
    min-width: 26px;
    margin-right: 20px;
}
.icon_admin_hover_line{
    min-width: 100%;
    display: flex;
    justify-content: flex-end;
    opacity: 0;
}
.admin_text:hover .icon_admin_hover_line{
    opacity: 1;
}
.icon_admin_hover{
    min-width: 104px;
    max-width: 104px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.icon_admin_hover svg{
    cursor: pointer;
}
.piv_line1_products_list{
    min-width: 90%;
    max-width: 90%;
    border: 1px solid rgba(225, 229, 234, 1);
    border-radius: 8px;
    margin-bottom: 10px;
}
.piv_line1_product{
    min-width: calc(100% - 16px);
    height: 35px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(225, 229, 234, 1);
    cursor: pointer;
    padding-left: 8px;
    padding-right: 8px;
}
.piv_line1_product svg{
    margin-right: 8px;
    opacity: 0;
}
.piv_line1_product:hover svg{
    opacity: 1;
}
.piv_line1_product:hover{
    background: rgba(247, 247, 247, 1);
}
.piv_line1_product:first-child{
    border-top-left-radius: 8px ;
    border-top-right-radius: 8px ;
}
.piv_line1_product:last-child{
    border-bottom-left-radius: 8px ;
    border-bottom-right-radius: 8px ;
}
.body_controls_block_line.rule label{
    display: flex;
    align-items: center;
}
.body_controls_block_line.rule label svg{
    margin-left: 8px;
}
