.segment{
    width: 100%;
    min-height: calc(100vh - 172px);
    position: relative;
    
}
.header-segment{
    width: calc(100% - 131px);
    height: 40px;
    padding-left: 40px;
    padding-right: 30px;
    margin-top: 42px;
}
.header-segment.dataset{
    height: auto;
}
.header-segment .slick-slide{
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: center;
    color: rgba(2, 2, 3, 0.15);
}
.slick-slider{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.slick-list{
    max-width: 85%;
    border-bottom: 1px solid rgba(245, 197, 56, 1)
}
.slick-track{
    display: flex !important;
    justify-content: space-around;
}
.slick-slide{
    min-width: calc((100% - 303px)  / 9);
    min-height: 38px;
    display: flex !important;
    align-items: center;
    justify-content: center;

}
.slick-slide div div{
    display: flex !important;
    align-items: center;
}
.createbut{
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.03em;
    text-align: left;
    text-transform: uppercase;
    color: rgba(2, 2, 3, 1);
    margin-left: 11px;
    margin-right: 11px;
    cursor: pointer;
}
.slick-slide div div svg{
    margin-right: 15px;
}
.body-segment{
    width: calc( 100% - 60px);
    margin-top: 64px;
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
}
.add-segment-but{
    display: flex;
    max-width: 390px;
    align-items: center;
    justify-content: space-between;

    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    letter-spacing: -0.01em;
    text-align: left;

    color: rgba(2, 2, 3, 0.15);


    cursor: pointer;
    margin-bottom: 2px;
}
.slickpr, .slicknx{
    cursor: pointer;
}
.bottom-segment{
    display: flex;
    width: calc(100% - 140px);
    position: absolute;
    bottom: 43px;
    padding-left: 70px;
    padding-right: 70px;
    justify-content: space-between;
}
.sbutton{
    max-width: 350px;
    min-width: 345px;
}
.dbuttondataset {
    max-width: 285px;
    min-width: 285px;
}

.popupInfo.addwork{
    max-width: calc(1110px - 80px);
    max-height: calc(544px - 92px);
}
.addwork .separator {
    width: 436px;
}
.add-segment-but.dataset{
    max-width: 185px;
    color:  rgba(2, 2, 3, 0.6);
}
.add-segment-but.dataset:hover{
    color:  #020203;

}
.add-segment-but.dataset svg path{
    fill: rgba(2, 2, 3, 1);
}
.add-segment-but.dataset:hover svg path{
    fill: rgba(2, 2, 3, 1);
}

.add-segment-but.dataset svg path:first-child{
    fill: rgba(245, 197, 56, 1);
    stroke: rgba(245, 197, 56, 1);
}
.add-segment-but.dataset:hover svg path:first-child{
    fill: rgba(65, 168, 177, 1);
    stroke: rgba(65, 168, 177, 1);
}

.datasethead{
    display: flex;
    padding-left: 53px;
    margin-bottom: 11px;
    position: relative;
    z-index: 9999;
}
.hold .header-segment{
    padding-left: 40px;
}
.topfield{
    margin-right: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    position: relative;
    z-index: 999;
}
.datasetbody{
    display: flex;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 20px;
    align-items: center;
    position: relative;
    margin-left: 33px;
}

.datasetbody:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(270deg, rgba(245, 197, 56, 0.01) 31.11%, #F5C538 82.25%); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}


.datasetbody svg {
    margin-right: 12px;
    
    position: relative;
    z-index: 999;
    cursor: pointer;
}
.datasetbody .single_refresh{
    opacity: 0;
}
.datasetbody:hover svg {

    opacity: 1;
}
.datasetbody:hover .single_refresh{
    opacity: 1;
}
.addwork .topfield, .addwork .topfield input{
    color: #fff;
}
.addwork .datasetbody{
    margin-top: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #020203;
    position: relative;
}
.addwork .datasetbody:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(270deg, rgba(245, 197, 56, 0.01) 31.11%, #F5C538 82.25%); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}

.addwork .datasetbody.active::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(90deg, #33A753 9.38%, rgba(51, 167, 83, 0) 68.41%); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;

}

.addwork .datasetbody:first-child{
    margin-top: 24px;
}
.addwork .topfield input{
    background:  rgba(45, 50, 57, 1);
    border: none;
}
.field0{
    width: 24px;
    margin-left: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    opacity: 0;
}
.datasetbody:hover .field0{
    opacity: 1;
}
.datasetbody.active .field0{
    opacity: 1;
}
.field1{
    min-width: 17%;
    max-width: 17%;
}
.field2{
    min-width: 9%;
    max-width: 9%;
}
.field3{
    min-width: 10%;
    max-width: 10%;
}
.field4{
    min-width: 9%;
    max-width: 9%;
}
.field5{
    min-width: 6%;
    max-width: 6%;
}
.field6{
    min-width: 7%;
    max-width: 7%;
}
.field7{
    min-width: 12%;
    max-width: 12%;
}
.field8{
    width: calc(100%);
}
.topfield input{
    max-width: calc(100% - 20px);
    width: 100%;
    background: none;
    border: 1px solid rgba(244, 245, 247, 1);
    color: rgba(2, 2, 3, 0.6);       
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
}
.popupInfo.dataset{
    width: calc(545px - 80px);
    height: calc(237px - 92px);
}
.dataset .buttonspopup{
    min-width: 82px;
}
.dataset .separator{
    width: 128px;
}
.adddataset-element{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.adddataset-element.bigdata{
    flex-wrap: wrap;
    justify-content: flex-start;
}
.MuiDropzoneArea-root{
    /* margin-top: 50px; */
}
.buttonDataset{
    width: 220px;
    height:39px;
    padding: 20px 0px 0px 0px;
    border-radius: 8px;
    background: rgba(28, 30, 32, 1);
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: center;
    color: white;
    cursor: pointer;
    position: relative;
}
.buttonDataset:hover{
    color: rgba(245, 197, 56, 1);
    background: none;
}
.buttonDataset:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(263.84deg, #F5C538 0%, rgba(28, 30, 32, 0) 97.46%); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}
.buttonDataset.inactive:hover::before{
    display: none;
}
.inactive{
    color: #020203;
    cursor: default;
}
.buttonDataset.inactive:hover{
    color: #020203;
}
.hold .popupInfo.dataset{
    left: 395px;
}
.MuiDropzoneArea-textContainer{
    width: 100%;
}
.zeras{
    min-width: 100%;
}
.dropanddrag{
    min-width: 100% !important;
    height: max-content;
    margin-bottom: 40px;
}
.MuiDropzoneArea-root{
    background: none;
    color: #fff;
    border: 1px dashed rgba(245, 197, 56, 1) !important;
    background-color: rgba(255,255,255,0) !important;
    min-height: max-content
}
.analitdeploy .MuiDropzoneArea-root{
    min-height: max-content
}
.uploadicon{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    max-width: 72px;
    justify-content: center;
}
.uploadiconline{
    width: 100%;
    display: flex;
    justify-content: center;
}
.uploadicon svg{
    margin-bottom: 24px;
}
.title_dropanddrag{


    font-size: 16px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: center;

    color: white;
   
    margin-top: 20px;

}
.text_dropanddrag{

    font-size: 16px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: center;

    color: rgba(255, 255, 255, 0.64);

}
.text_dropanddrag.inactive{
    color: rgba(255, 255, 255, 0.6);
}
.title_dropanddrag.inactive{
    color: rgba(255, 255, 255, 0.6);
}
.buttom_dropanddrag_line{
    width: 100%;
    display: flex;
    justify-content: center;
}
.buttom_dropanddrag{
    width: 137px;
    height: 16px;
    padding: 12px 20px;
    border-radius: 8px;


    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 15.8px;
    letter-spacing: -0.03em;
    text-align: center;
    color: rgba(2, 2, 3, 1);
    background: rgba(245, 197, 56, 1);
    position: relative;
}
.buttom_dropanddrag.inactive{
   
    color: rgba(2, 2, 3, 0.4);
    background: rgba(244, 245, 247, 0.6);
    cursor: default;
}
.buttom_dropanddrag:hover{
    background: none;
    color: rgba(2, 2, 3, 1);
}
.buttom_dropanddrag:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(263.84deg, #F5C538 0%, rgba(28, 30, 32, 0) 97.46%); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}
.buttom_dropanddrag:hover{
    color: rgba(245, 197, 56, 1);
}
.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomLeft{
    display: none;
}
.tableexcel_line{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.tableexcel_head{
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}
.tableexcel_select select{
    width: 314px;
    height: 60px;
    padding: 20px ;
    border-radius: 8px ;
    background: rgba(45, 50, 57, 1);
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(245, 197, 56, 1);
    outline: none;
}
.tableexcel_select{
    width: 100%;
    display: flex;
}
.params_dataset{
    width: calc(100% - 314px);
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.tableexcel_body{
    width: 100%;
    /* max-height: 270px; */
    margin-top: 10px;
    overflow-x: scroll;
}
.tableexcel_body table th{
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    color: white;
    padding-right: 20px;
    text-transform: capitalize;
}
.tableexcel_body table{
    /* margin-bottom: 20px; */
}

.tableexcel_body table {
    border: 0; 
    border-collapse: separate; 
    border-spacing: 0 5px;
  }
  .tableexcel_body thead tr th {
    border-bottom: 1px solid  rgba(245, 197, 56, 1);
    border-collapse: separate; 
    border-spacing: 5px 5px;
  } 
  .tableexcel_body tbody tr td {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    border-collapse: separate; 
    border-spacing: 5px 5px;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    color: white;

  }
  .tableexcel_body tbody tr{
    height: 30px;
  }
  .tableexcel_body tbody tr td{
    padding-top: 6px;
    white-space: nowrap;
    padding-right: 5px;
  }
  .popupInfo.addwork.attach{
    height: 640px;
    min-height: 640px;
  }
  .tableexcel_body::-webkit-scrollbar{
    height: 10px;
    width: 12px;
  }
  .tableexcel_body::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #020203;
}
 
 .tableexcel_body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(245, 197, 56, 1);
}

.filter__menu-list::-webkit-scrollbar{
    height: 5px;
    width: 5px;
  }
.filter__menu-list::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(244, 245, 247, 1);
    margin-top: 10px;
}
 
.filter__menu-list::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(245, 197, 56, 1);
}

/* .filter__menu-list{
    max-width: 93%;
} */
.filter__menu-list .filter__option{
    padding-right: 15px !important;
 }
.filter__menu-list::-webkit-scrollbar{
    height: 5px;
    width: 5px;

  }
  .filter__menu-list::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(244, 245, 247, 1);
    margin-top: 10px;
}
 
.filter__menu-list::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(245, 197, 56, 1);
}






.line1_params_dataset, .line2_params_dataset{
    display: flex;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #fff;

}
.line2_params_dataset{
    margin-top: 22px;
}
.param1_title_dataset{
    width: 90px;
    margin-left: 40px;
    color: rgba(255,255,255, 0.6);
}
.param1_val_dataset{
    
}
.param2_title_dataset{
    min-width: 115px;
    color: rgba(255,255,255, 0.6);
    margin-left: 40px;
}
.ptd3{
    min-width: 200px
}
.css-1hb7zxy-IndicatorsContainer{
    /* margin-right: 8px; */
}
.selectproduct{
    cursor: pointer;
}

.filter__menu-list{
    overflow-x: hidden;
    border-top: 1px solid  rgba(245, 197, 56, 1);
}
.css-ungm9i-option{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.css-1fdsijx-ValueContainer{
    padding-left: 17px !important;
}
.tableexcel_line .selectplace{
    color: rgba(255, 255, 255, 1) !important;
}
.datasetname{
    width: 200px;
    max-width: 200px;
    min-width: 200px;
    height: 18px;
    padding: 10px 10px;
    border-radius: 8px;
    color: #fff;
}
.dataset .filter__menu-list{
    width: 100%;
}
.tableexcel_select .filter__menu-list{
    width: 100%;
}
.but_depluyment{
    width: 137px;
    height: 19px;
    padding: 20px;
    border-radius: 8px;
    background:  rgba(28, 30, 32, 1);
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: center;
    cursor: pointer;
    margin-right: 20px;
    position: relative;
}
.but_depluyment.active{
    background: rgba(45, 50, 57, 1);
    color: rgba(245, 197, 56, 1);
}
.but_depluyment:hover{
    color: rgba(245, 197, 56, 1);
}


.but_depluyment.active:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(263.84deg, #F5C538 0%, rgba(28, 30, 32, 0) 97.46%); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}


.stage_atach{
    width: 100%;
    margin-top: 28px;
    border-bottom: 1px solid rgba(245, 197, 56, 1);
    display: flex;
    justify-content: flex-start;
    margin-bottom: 24px;
}
.buton_stage{
    width: 72px;
    height: 18px;
    padding: 6px 10px;
    border-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: center;
    color: rgba(244, 245, 247, 0.8);
    margin-right: 20px;
}
.buton_stage.active{
    background: rgba(245, 197, 56, 1);
    color: rgba(2, 2, 3, 1);
    cursor: pointer;
}
.buton_stage.inactive{
    color: rgba(245, 197, 56, 1);
    cursor: pointer;
}
.checkWithoutdate{
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.checkWithoutdate svg {
    margin-right: 12px;
    cursor: pointer;
}
.hover_checkWithoutdate{
    display: none;
}
.active_checkWithoutdate{
    display: none;
}
.checkWithoutdate:hover .hover_checkWithoutdate{
    display: block;
}
.checkWithoutdate:hover .default_checkWithoutdate{
    display: none;
}
.checkWithoutdate:hover{
    color: rgba(245, 197, 56, 1);
}
.checkWithoutdate.active .active_checkWithoutdate{
    display: block;
}
.checkWithoutdate.active .default_checkWithoutdate{
    display: none;
}
.checkWithoutdate.active:hover .active_checkWithoutdate{
    display: none;
}
.adddataset-element.bigdata .MuiDropzoneArea-root{
    min-height: 270px !important;
}
.analitdeploy .popupInfo.addwork.attach{
        overflow-y: scroll;
}



.analitdeploy .popupInfo.addwork.attach::-webkit-scrollbar{
    height: 0px;
    width: 0px;

  }
  .analitdeploy .popupInfo.addwork.attach::-webkit-scrollbar-track {
    border-radius: 0px;
    background: #fff;
   
}
 
.analitdeploy .popupInfo.addwork.attach::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #fff;
}


.tablevklad{

    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: left;
    
    color: rgba(244, 245, 247, 1);
    width: 100%;
    display: flex;
    align-items: center;
}   
.tablevklad .separator{
    width: calc(100% - 250px);
    display: inline-block;
    height: 1px;
    background: rgba(255, 255, 255, 0.15);
    margin-left: 40px;
}
.analitdeploy .big_circle{
    border: 2px solid rgb(255 255 255);
}

.analitdeploy .loading_text{
   color: #fff;
}


.analitdeployscroll::-webkit-scrollbar{
    height: 10px;
    width: 12px;
  }
  .analitdeployscroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(2, 2, 3, 1);
}
 
 .analitdeployscroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(2, 2, 3, 1);
}
.but_next_stepanalitics_line{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 14px;
    
}
.but_next_stepanalitics{
    width: 177px;
    height: 33px;
    padding: 12px 20px;
    border-radius: 8px;
    background:  rgba(28, 30, 32, 1);
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: center;

    color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}
.but_next_stepanalitics:hover{
    background: none;
    color: rgba(255, 255, 255, 1);
}
.but_next_stepanalitics:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(263.84deg, #F5C538 0%, rgba(28, 30, 32, 0) 97.46%); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}
.but_next_stepanalitics:hover{
    color: rgba(245, 197, 56, 1);
}
.loading_lines{
    margin-top: 80px;
}
.analitdeploy .yui{
    justify-content: center;
}
.but_next_stepanalitics.downloads{
    width: 177px;
}
.but_depluyment.inactive{
    color: rgba(2, 2, 3, 1);
    cursor: default;
}
.pop-input.datasetname.active{
    width: 644px;
}
.selectproduct.active .css-opmbme-control{
    margin-right: 0px;
}
.typedatasets{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* margin-top: 24px; */
    margin-bottom: 10px;
}
.typedatasets span{
    min-width: 100%;
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

}
.typedatasets span svg{
    margin-right: 12px;
}
.line_vvod_anali{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.onetime_but{
    display: flex;
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #fff;
    align-items: center;
     cursor: pointer;
}
.onetime_but svg{
    margin-right: 8px;
}
.adddataset-element.bigdata.center{
    justify-content: flex-start;
}
.shedule{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    color: #fff;
}
.shedule_line1{
    min-width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 60px;
}
.shedule_line2, .shedule_line3{
    min-width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}
.shedule_line3{
    justify-content: space-between;
}
.time_shedule, .date_shedule{
    display: flex;
    align-items: center;
}
.input_every{
    width: 140px;
    height: 18px;
    padding: 10px;
    border-radius:4px ;
    background: rgba(45, 50, 57, 1);

    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.03em;

    color: rgba(255, 255, 255, 1);
    outline: none; 
    border: none;
    margin-right: 12px;
}
.shedule_line2_col1, .shedule_line3_col1{
    min-width: 128px;
    text-align: left;
    margin-right: 20px;
    color: rgba(255, 255, 255, 0.6);
}
.shedule_line3_col4{
    margin-right: 12px;
    margin-left: 12px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;

}
.checkpolden{
    display: flex;
    margin-left: 20px;
}
.checkpolden span{
    display: flex;
    height: 26px;
    width: 23px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 6px 10px 6px 10px;
    border-radius: 4px 0 0 4px;
    background: rgba(45, 50, 57, 1);
}
.checkpolden span:last-child{
    border-radius: 0px 4px 4px 0px;
}
.checkpolden span.active{
    background: rgba(245, 197, 56, 1);
    color: rgba(2, 2, 3, 1);
}
.shedule_line2{
    position: relative;
    z-index: 99999;
}
.react-datepicker__input-container input{
    width: 160px;
    height: 18px;
    padding: 10px 20px;
    border-radius: 4px;

    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;

    background: rgba(45, 50, 57, 1) !important;
    color: #fff;
    outline: none;
    border: 0px;
}
.tableexcel_head .react-datepicker__input-container input{
    height: 38px;
    border-radius: 8px;
}
.react-datepicker, .react-datepicker__header{
    background:rgba(28, 30, 32, 1);
    border: 0px !important;
    background-color:rgba(28, 30, 32, 1) !important;
}
.react-datepicker__current-month{

    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
   
    color: rgba(255, 255, 255, 1) !important;
}
.react-datepicker__triangle{
    display: none;
}
.react-datepicker__day-name{

    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: -0.01em;

    color: rgba(245, 197, 56, 1) !important;
}
.react-datepicker__day{

    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: -0.01em;

    color: rgba(255, 255, 255, 1) !important;
}
.react-datepicker__day--selected{
    border-radius: 0.3rem;
    background-color: rgba(245, 197, 56, 1) !important;
    color: #000 !important
}
.react-datepicker__day--disabled{
    border-radius: 0.3rem;
    
    color: #cccccc28 !important
}
.react-datepicker__day.react-datepicker__day--disabled:hover{
    border-radius: 0.3rem;
    background-color: rgba(28, 30, 32, 1) !important;
    color: #cccccc28 !important
}
.react-datepicker__header{
    border-bottom: 0px !important;
}
.react-datepicker__month-container{
    border-radius: 4px;
}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
    background-color: rgba(245, 197, 56, 1) !important;
}
.loading_container{
    width: 100%;
    display: flex;
    justify-content: center;
}
.preloadbigdataset{
    min-width: 100%;
}
.preloadbigdataset .big_circle{
    border: 2px solid rgb(255 255 255);
}
.preloadbigdataset .loading_block{
    margin-top: 75px;
}
.preloadbigdataset .loading_text{
    color: #fff;
}
.centerflex{
    justify-content: space-between;
    margin-top: 50px;
}
.cursorenone{
    cursor: default;
}
.pagdatasets{
    margin-top: 0px !important;
    left: 0px !important;
    min-width: fit-content !important;
    max-width: fit-content !important;
    margin: inherit !important;
    margin-bottom: 0px !important;
}
.line_head{
    border-bottom: 1px solid rgba(245, 197, 56, 1);
}
.datasets .header-segment.dataset{
    margin-top: 40px;
}
.datasets .betta{
    top: 8px;
}
.countpagepagination{

    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(2, 2, 3, 0.3);
    /* position: absolute;
    top: 48px; */
    margin-right: 30px;
}
.pagination_line{
    display: flex;
    padding-left: 65px;
    align-items: center;
    position: relative;
    margin-top: 10px;
}
.svitchtime{
    display: flex;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    min-width: 203px;
    margin-left: 20px;
    margin-right: 20px;
    align-items: center;
}
.tableexcel_select.place .selectplace{

    font-size: 16px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    color: rgba(255, 255, 255, 0.4) !important
}       
.svitchtime_butt{
    width: 43px;
    height: 38px;
    background: rgba(45, 50, 57, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: center;
    color: #fff;
    cursor: pointer;
    
}
.svitchtime_text{
    max-width: 120px;
}
.svitchtime_butt.active{
    background: rgba(245, 197, 56, 1);
    color: rgba(2, 2, 3, 1);
}
.butr1{
    border-top-left-radius:4px;
    border-bottom-left-radius:4px;
    margin-left: 12px;
}
.butr2{
    border-top-right-radius:4px;
    border-bottom-right-radius:4px;
}
.zerascif{
    min-height: max-content !important;
    height: max-content !important;
    max-width: calc(1110px - 252px) !important;
}
.popupInfo.dataset .namepopup{

    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    text-transform: none;
}
.popupInfo.dataset .buttonsave, .popupInfo.dataset .buttonexit{
    height: 32px;
}
.zerascif .loading_block{
    margin-bottom: 70px;

}
.preloadbigdataset.bq_load .loading_block{
    margin-top: 0px;
    padding-top:0px;
}


.gray .MuiDropzoneArea-root{
    border: 1px dashed rgba(244, 245, 247, 0.6) !important;
}

.loading_text span{ 

    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: center;
    position: relative;
    top: 8px;
}




/*--------------------NEW DATASET-------------------*/


.newlistdataset{
    width: calc(100% - 65px);
}

.title_dataset_list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100%;
    margin-bottom: 20px;
}
.left_title_dataset_list{

    font-size: 24px;
    font-weight: 600;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(0, 0, 0, 1);

}
.left_title_dataset_list span{
    color: rgba(142, 142, 147, 1);
    margin-left: 10px;
}
.right_title_dataset_list{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.input_title_dataset_list{
    width: 281px;
    height: 38px;
    padding: 0px 8px 0px 8px;
    border-radius: 8px;
    border: 1px solid rgba(231, 234, 238, 1);
    display: flex;
    align-items: center;
}
.input_title_dataset_list input{
    outline: none;
    border: 0px solid rgba(231, 234, 238, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}
.input_title_dataset_list input{
    color: rgba(2, 2, 3, 0.4);
    
}
.right_title_dataset_list .filters{
    width: 23px;
    height: 29px;
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    border: 1px solid rgba(231, 234, 238, 1);
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(2, 2, 3, 0.6);
    display: flex;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    position: relative;
}  
.right_title_dataset_list .filters:hover svg path{
    fill: rgba(0, 0, 0, 1);
} 
.right_title_dataset_list .filters:hover{
    border: 1px solid rgba(242, 242, 247, 1);
    background: rgba(242, 242, 247, 1);
}

.right_title_dataset_list .filters .tooltipe{
    display: none;
    position: absolute;
    width: 126px;
    height: fit-content;
    border-radius: 4px;
    padding: 8px;
    background: rgba(244, 245, 247, 1);
    font-family: Manrope;
    font-weight: 400;
    font-size: 8px;
    line-height: 9.6px;
    letter-spacing: -1%;
    opacity: 1;
    top: -50px;
    right: 0;
    z-index: 9;
}
.right_title_dataset_list .filters:hover .tooltipe{
    display: block;
}

.newlistdataset .datasethead{
    padding-left: 20px;
    min-width: calc(100% - 20px);
}
.newlistdataset .datasetbody{
    margin-left: 0px;
    min-width: calc(100% - 20px);
}
.tags_dataset{
   
    height:27px;
    padding: 5px 0px 5px 0px;
    border-radius: 4px ;
    border: 1px solid rgba(244, 245, 247, 1);

    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    display: flex;
    align-items: center;
}
.tags_dataset span{
   
    padding: 2px 4px 2px 4px;
    border-radius: 4px;
    background: rgba(66, 133, 244, 0.2);
    margin-left: 4px;
}
.single_refresh{
    display: flex;
    align-items: center;
    margin-right: 20px;

    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(2, 2, 3, 0.6);
    cursor: pointer;
    position: relative;
    z-index: 999;
}
.single_refresh svg{
    margin-right: 8px

}
.betta_line {
    width: fit-content;
    height: 20px;
    top: -3px;
    right: 40px;
    position: initial;
    display: flex;
    cursor: pointer;
    align-items: center;
}
.betta_line svg{
    margin-left: 12px;
}
.betta_line .betta{
    position: relative;
    top: 0px;
    right: 0px;
}
.betta_line .single_refresh{
    margin-right: 0px;
    margin-left: 12px;
}
.datasetnewtitle{

    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(2, 2, 3, 0.6);
}
.left_title_dataset_list .add-segment-but{
    text-transform: none;
}
.add-segment-but svg{
    margin-right: 8px;
}
.tolatipa_block{
    position: relative;
}
.tolatipa_block:hover .arica {
    background: rgba(242, 242, 247, 1);
    border-radius: 4px;
}
.tolatipa{
    width: 142px;
    height: 60px;
    padding: 12px;
    border-radius: 8px ;
    border: 1px solid rgba(231, 234, 238, 1);
    opacity: 0;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    right: 0px;
    z-index: 999;
    background: #fff;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 14.7px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(0, 0, 0, 0.6);
}
.tolatipa span{
    min-width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.tolatipa span svg{
    margin-right: 8px;
}
.del_tooltip{
    color:rgba(255, 59, 48, 0.8)
}
.datasetbody:hover{
    position: relative;
    z-index: 9999;
}
.datasetbody .tolatipa_block:hover .tolatipa{
    opacity: 1;
}
.ref_tooltip:hover {
    color: rgba(0, 0, 0, 0.8);
}
.del_tooltip:hover{
    color:rgba(255, 59, 48, 1)
}
.filtras{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.filtras svg{
    opacity: 0;
}
.topfield.filtras:hover svg{
    opacity: 0;
}
.dataset_head_back{
    cursor: pointer;
    margin-left: 15px;
}
.dataset_head_back:hover .def_icon {
    display: none;
}
.dataset_head_back:hover .hov_icon {
    display: block;
    position: relative;
    top:-2px
}
.refreshpopup .titlePopupDelete{
    font-size: 16px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}
.refreshpopup .titlePopupDelete span{
    font-family: Manrope;
    font-size: 32px;
    font-weight: 500;
    line-height: 35.2px;
    letter-spacing: -0.03em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(245, 197, 56, 1);
    text-transform: inherit;
}

.refreshpopup .buttonPopupDelete{
    background: rgba(28, 30, 32, 1);
    border-radius: 10px;
    min-width: 259px;
    display: flex;
    align-items: center;
    padding-top: 0px;
    min-height: 58px;
    text-align: center;
    justify-content: center;
}
.refcsv .buttonPopupDelete{
    min-height: 40px;
}
.refreshpopup .add_refresh{
    background: rgba(245, 197, 56, 1);
    color: #000;
}
.refreshpopup .popupInfo{
    background: rgba(0, 0, 0, 1);
}
.refreshpopup .buttonPopupDelete:hover{
    color: rgba(245, 197, 56, 1);
}
.refreshpopup .add_refresh:hover{
    background: rgba(65, 168, 177, 1);
    color: rgba(2, 2, 3, 1);
}
.refreshpopup .add_refresh:hover::before{
    content: none;
}



.refcsv .formrefresh .buttonsPopupDelete .MuiDropzoneArea-root{
    min-height: fit-content !important;
    border: 0px !important;
}

.refcsv .formrefresh .buttonsPopupDelete .MuiDropzoneArea-text{
    margin-top: 0px;
    margin-bottom: 0px;
}
.refcsv .formrefresh .buttonsPopupDelete  .buttom_dropanddrag_line{

    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(2, 2, 3, 1);
}
.refcsv .formrefresh .buttonsPopupDelete .dropanddrag{
    margin-bottom: 0px;
}
.formrefresh .subtitle{
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(244, 245, 247, 1);
    text-align: center;
    display: block;
    margin-bottom: 10px;
}
.formrefresh .trepop{
    font-family: Manrope;
    font-size: 32px;
    font-weight: 500;
    line-height: 35.2px;
    letter-spacing: -0.03em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(234, 67, 52, 1);
    margin-bottom: 20px;
}
.formrefresh .errorname{
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(244, 245, 247, 0.8);
    display: block;
    width: 100%;
    margin-bottom: 10px;
}
.formrefresh .errorextra{
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(244, 245, 247, 0.8);
    display: block;
    width: 100%;
    margin-bottom: 10px;
}
.formrefresh .errormissing{
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(244, 245, 247, 0.8);
    display: block;
    width: 100%;
}
.formrefresh .big_circle{
    border: 2px solid rgb(255 255 255);
}
.formrefresh .loading_text{
    color: #fff;
}
.formrefresh .big_circle{
    width: 63px;
    height: 63px;
}
.formrefresh .small_circle{
    width: 26px;
    height: 26px;
    left: 18px;
    top: 18px;
    animation: Orbita2 15s linear infinite;
}
.formrefresh .loading_icon{
    min-height: 110px;
}
@keyframes Orbita2 {
    from {
      -webkit-transform: rotate(0deg) translateX(50px) rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg) translateX(50px) rotate(-360deg);
    }
}
.formrefresh .loading_text{
    font-size: 16px;
    color: rgba(244, 245, 247, 1);
}
.formrefresh .loading_text span{
    font-size: 32px;
    color: rgba(244, 245, 247, 1);
}
.tableexcel_select .filter__single-value{
    color: #fff !important;
}
.body-segment_dataset_head{
    display: flex;
    min-width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(245, 197, 56, 1);
    padding-bottom: 10px;
}
.body-segment_dataset_head .betta.new{
    position: relative;
    top: 0;
    left: 0;
}
.body-segment_dataset_head .betta_inside{
    
}
.ref_tooltip.inactive{

    color: rgba(229, 229, 234, 1);
    cursor: default;
}
.ref_tooltip.inactive:hover{

    color: rgba(229, 229, 234, 1);
    cursor: default;
}
.ref_tooltip.inactive svg path{
    fill: rgba(229, 229, 234, 1);
}

.lineflex{
    display: flex;
    min-width: 100%;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 999;
}
.lineflex svg{
    opacity: 0;
    position: relative;
    z-index: -1;
    cursor: pointer;
}
/* .topfield:hover .lineflex svg{
    opacity: 1;
    z-index: 1;
} */
.tooltipe_filter{
    position: absolute;
    top: 22px;
    left: -50px;
    background: #fff;
    border: 1px solid rgba(231, 234, 238, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 8px;
    min-width: 187px;
    z-index: 999;
    font-family: Manrope;
    font-weight: 500;
    font-size: 12px;
    line-height: 105%;
    letter-spacing: -3%;
    vertical-align: middle;
    color: rgba(2, 2, 3, 0.6);
    opacity: 0;
    z-index: -10;
    display: none;
}
.sort_filter_line{
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
}
.sort_filter_line svg{
    opacity: 1;
    z-index: 99;
    margin-right: 8px;
}
.sort_filter{
    border-bottom: 1px solid rgba(231, 234, 238, 1);
}
.count_filter{
    min-width: calc(100% - 24px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    padding-bottom: 0px;
    
}
.show_all_filter, .reset_filter{
  
    cursor: pointer;    
}
.show_all_filter_left{
    display: flex;
    align-items: center;
    font-family: Manrope;
    font-weight: 500;
    font-size: 8px;
    line-height: 105%;
    letter-spacing: -3%;
    vertical-align: middle;
    color: rgba(0, 122, 255, 1);
}
.show_filter{
    font-family: Manrope;
    font-weight: 500;
    font-size: 8px;
    line-height: 105%;
    letter-spacing: -3%;
    vertical-align: middle;
    color: rgba(2, 2, 3, 0.6);
}
.search_filter{
    min-width: calc(100% - 24px);
    padding: 8px 12px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;
}
.search_filter_input{
    width: calc(100% - 36px) !important;
    min-width: calc(100% - 36px) !important;
    border: 1px solid rgba(244, 245, 247, 1);
    background: #fff;
    outline: none;
    border-radius: 4px;
    padding-right: 8px;
    padding-left: 28px;
    font-family: Manrope;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 105%;
    letter-spacing: -3%;
    color: rgba(2, 2, 3, 1)
}
.search_filter_input::placeholder{
    font-family: Manrope;
    font-weight: 400;
    font-size: 12px;
    line-height: 105%;
    letter-spacing: -3%;
    color: rgba(0, 0, 0, 0.2);
}
.topfield:hover{
    z-index: 999999;
}
.icon_search{
    opacity: 1 !important;
    z-index: 1 !important;
    position: absolute !important;
    left: 20px;
}
.result_filter{
    min-width: calc(100% - 24px);
    padding: 8px 12px;
    display: flex;
    max-height: 90px;
    overflow: auto;
    flex-wrap: wrap;
}
.result_filter_line{
    min-width: 100%;
    display: flex;
    align-items: center;
}
.result_filter_line svg{
    opacity: 1 !important;
    z-index: 1 !important;
    margin-right: 10px;
}
.but_filter{
    border-top: 1px solid rgba(231, 234, 238, 1);
    min-width: calc(100% - 24px);
    padding: 8px 12px;
    padding-bottom: 0px;
    font-family: Manrope;
    font-weight: 600;
    font-size: 12px;
    line-height: 105%;
    letter-spacing: -1%;
    vertical-align: middle;
    color: rgba(2, 2, 3, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.but_filter_apply{
    border: 1px solid rgba(245, 197, 56, 1);
    background: rgba(245, 197, 56, 1);
    border-radius: 4px;
    padding: 8px 20px;
    cursor: pointer;
}   
.but_filter_cancel{
    border: 1px solid var(--Grays-Gray-5, rgba(229, 229, 234, 1));
    border-radius: 4px;
    padding: 8px 20px;
    cursor: pointer;

}   
.but_filter_apply:hover{
    border: 1px solid rgba(65, 168, 177, 1);
    background: rgba(65, 168, 177, 1);
}
.openfilter{
    position: relative;
}
/* .openfilter:hover .tooltipe_filter{
    opacity: 1;
    z-index: 1;
    display: block;
} */